import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as SubscriptionIcon } from '../../Assets/Images/svg/pageSubscriptionIcon.svg';
import CustomButton from '../../Components/Common/CustomButton';
import NavigationBar from '../../Components/Common/NavigationBar/index';
import Spinner from '../../Components/Common/Spinner';
import TitleCard from '../../Components/Common/TitleCard';
import { AppDispatch, RootState } from '../../Store';
import {
  cancelSubscriptionAsync,
  getCustomerPackageDetailsAsync,
  resubscribeAsync,
} from '../../Utils/api';
import { ButtonColor, DashBoardTab, Role, SubscriptionStatus } from '../../Utils/constants';
import { formatDateString, makeFirstLetterCapital } from '../../Utils/helperFunctions';
import InfoAttribute from '../Dashboard/InformationSummary/InfoAtrribute';
import SubscriptionModal from './SubscriptionModal';

type SubscriptionInfoProps = {
  status: string;
  activationDate: string;
  createdAt: string;
  packageName: string;
};

const SubscriptionInfo = ({
  status,
  activationDate,
  createdAt,
  packageName,
}: SubscriptionInfoProps) => {
  const activationDateParsed = activationDate ? new Date(activationDate) : null;
  const createdAtParsed = new Date(createdAt);

  const dateToUse =
    activationDateParsed && activationDateParsed > createdAtParsed
      ? activationDateParsed
      : createdAtParsed;

  const dateToUseString = dateToUse.toISOString();

  const updatedTagline = `${
    activationDate ? `Subscribed on ${formatDateString(dateToUseString)}` : ''
  }`;
  return (
    <div className="flex flex-row w-full justify-between pb-3">
      <InfoAttribute
        title="Status"
        value={status ? makeFirstLetterCapital(status).replaceAll('-', ' ') : 'Not Available'}
        textColor={status === SubscriptionStatus.CANCELLED ? 'text-red-0' : ''}
      />
      <InfoAttribute
        title="Active Since"
        value={createdAt ? formatDateString(createdAt) : 'Not Available'}
      />

      <InfoAttribute
        title="Support Package"
        value={packageName ? makeFirstLetterCapital(packageName) : 'Not Available'}
        tagline={updatedTagline}
      />
    </div>
  );
};

const Subscription = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const subscriptionDetails = useSelector((state: RootState) => state.subscription);
  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const customerId = useSelector((state: RootState) => state.admin?.currentCustomer);
  const activationDate = useSelector(
    (state: RootState) => state.signup.customer?.customer?.activationDate,
  );
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = useState(false);
  const [isResubscribeModalOpen, setIsResubscribeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (role === Role.ADMIN) {
      dispatch(
        getCustomerPackageDetailsAsync(setIsLoading, setErrorMessage, navigate, true, customerId),
      );
    } else {
      dispatch(getCustomerPackageDetailsAsync(setIsLoading, setErrorMessage, navigate, false));
    }
  }, []);

  const onCancelSubscriptionClick = () => {
    setIsCancelSubscriptionModalOpen(false);

    if (role === Role.ADMIN) {
      dispatch(cancelSubscriptionAsync(setIsLoading, setErrorMessage, navigate, true, customerId));
    } else {
      dispatch(cancelSubscriptionAsync(setIsLoading, setErrorMessage, navigate, false));
    }
  };

  const onResubscribeClick = () => {
    setIsResubscribeModalOpen(false);

    if (role === Role.ADMIN) {
      dispatch(resubscribeAsync(setIsLoading, setErrorMessage, navigate, true, customerId));
    } else {
      dispatch(resubscribeAsync(setIsLoading, setErrorMessage, navigate, false));
    }
  };

  const getButtonClass = (status: string) => {
    if (status === SubscriptionStatus.CANCELLED) {
      return ButtonColor.White;
    }
    return ButtonColor.Red;
  };

  const getModalFunction = (status: string) => {
    if (status === SubscriptionStatus.CANCELLED) {
      return setIsResubscribeModalOpen;
    }
    return setIsCancelSubscriptionModalOpen;
  };

  return (
    <div className="flex flex-row pb-[20px] px-[23px]">
      <div>
        <NavigationBar tab={DashBoardTab.SUBSCRIPTION} />
      </div>
      <div className="flex flex-col ml-[33px] w-full">
        <TitleCard title="Subscription" icon={SubscriptionIcon} />

        <div className="flex flex-col items-start p-6 bg-white-0 border rounded-[10px] border-gray-2 h-full gap-y-3">
          {isLoading && <Spinner adjustContainerHeight />}
          {!isLoading && (
            <>
              <SubscriptionInfo
                status={subscriptionDetails?.status}
                activationDate={subscriptionDetails?.subscriptionDate ?? ''}
                createdAt={activationDate || ''}
                packageName={subscriptionDetails?.package}
              />
              <div className="w-full h-[1px] bg-gray-17"></div>
              <div className="flex flex-row gap-x-28 w-full">
                <div className="flex flex-col gap-y-4 w-full md:w-1/2">
                  <div className="flex flex-col gap-y-4 w-full">
                    <h3 className="text-sm poppins-500 text-left text-gray-1 -mb-2">
                      {subscriptionDetails?.status === SubscriptionStatus.CANCELLED
                        ? 'Re-subscribe'
                        : 'Cancel Subscription'}
                    </h3>
                    <p className="text-xs text-left text-gray-1 opacity-70 poppins-400">
                      {subscriptionDetails?.status === SubscriptionStatus.CANCELLED
                        ? 'To start all activity and usage you will have to Re-subscribe.'
                        : "By choosing to cancel subscription, all activity and usage will stop. Please note you'll have to clear any remaining balance to cancel the billing account."}
                    </p>
                    <p className="text-xs text-left text-gray-1 opacity-70 mt-8 poppins-400">
                      {subscriptionDetails?.status === SubscriptionStatus.ACTIVE
                        ? 'When you cancel subscription, any billable activity of your services will stop, your billable resources may be deleted, and your application may stop functioning.'
                        : null}
                    </p>
                    <p className="text-xs text-left text-gray-1 opacity-70 -mt-3 poppins-400">
                      {subscriptionDetails?.status === SubscriptionStatus.ACTIVE
                        ? 'Any unbilled charges to date will be billed to the linked account.'
                        : null}
                    </p>
                    <div className="w-full flex justify-end mt-4">
                      <CustomButton
                        className="w-2/5 font-bold poppins-500"
                        onClick={() => getModalFunction(subscriptionDetails?.status)(true)}
                        colorClass={getButtonClass(subscriptionDetails?.status)}
                        disabled={
                          subscriptionDetails?.status === SubscriptionStatus.PENDING_CANCELLATION
                        }
                      >
                        {subscriptionDetails?.status === SubscriptionStatus.CANCELLED
                          ? 'Re-subscribe'
                          : 'Cancel Subscription'}
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>

              {subscriptionDetails?.status === SubscriptionStatus.PENDING_CANCELLATION && (
                <p className="text-[10px] text-center text-red-0 max-w-[300px]">
                  Your subscription will be cancelled within 48 hours.
                </p>
              )}

              {errorMessage ? (
                <p className="text-[10px] text-center text-red-0 max-w-[300px]">{errorMessage}</p>
              ) : null}
            </>
          )}
        </div>
        {isCancelSubscriptionModalOpen && (
          <SubscriptionModal
            setIsOpen={setIsCancelSubscriptionModalOpen}
            title="Are you sure you want to cancel subscription?"
            message={`By choosing to cancel subscription, all activity and usage will stop, Please note you'll have to ${'\n'}clear any remaining balance to cancel the billing account.`}
            buttonLabel="Cancel Subscription"
            buttonColorClass={ButtonColor.Red}
            onButtonClick={onCancelSubscriptionClick}
          />
        )}

        {isResubscribeModalOpen && (
          <SubscriptionModal
            setIsOpen={setIsResubscribeModalOpen}
            title="You are about to Re-subscribe"
            message="You will be charged for the subscription from the primary card that you added"
            buttonLabel="Re-subscribe"
            buttonColorClass={ButtonColor.DARK_BLUE_2}
            onButtonClick={onResubscribeClick}
          />
        )}
      </div>
      <ToastContainer
        className="!w-max"
        position="bottom-center"
        hideProgressBar={true}
        autoClose={8000}
        closeOnClick
        pauseOnHover
      />
    </div>
  );
};
export default Subscription;
