import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DownloadPdfIcon from '../../Assets/Images/svg/downloadPdfIcon.svg';
import { ReactComponent as BillingHistoryIcon } from '../../Assets/Images/svg/pageBillingHistory.svg';
import DataTable from '../../Components/Common/DataTable';
import NavigationBar from '../../Components/Common/NavigationBar/index';
import TitleCard from '../../Components/Common/TitleCard';
import { AppDispatch, RootState } from '../../Store';
import { fetchInvoicesAsync, fetchSignedInvoiceUrlAsync } from '../../Utils/api';
import {
  BILLING_HISTORY_TABLE_HEADERS,
  DashBoardTab,
  PER_PAGE_LIMIT,
  Role,
} from '../../Utils/constants';
import { formatBillingMonth, formatDateString } from '../../Utils/helperFunctions';

type Invoice = {
  month: string;
  amount: string;
  document_no: string;
  status: string;
  paid_on: string;
  action?: string;
};

const BillingHistory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const invoicesDetails = useSelector((state: RootState) => state.invoices);
  const identificationNumber = useSelector(
    (state: RootState) => state.signup?.customer?.customer?.identificationNumber,
  );
  const totalItems = useSelector((state: RootState) => state.invoices?.totalItems);
  const [isInitialState, setIsInitialState] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');
  const [selectedActionItem, setSelectedActionItem] = useState<string | number>('');
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const role = useSelector((state: RootState) => state.admin?.adminUser?.customer?.user?.role);
  const customerId = useSelector((state: RootState) => state.admin?.currentCustomer);

  useEffect(() => {
    const invoices: Invoice[] = invoicesDetails.invoices.map((invoice) => ({
      month: invoice.thresholdInvoice
        ? `Threshold ${formatBillingMonth(invoice.billingMonth)}`
        : formatBillingMonth(invoice.billingMonth),
      amount: `${
        Number(invoice.grossAmount).toLocaleString() === '0'
          ? '0.00'
          : Number(invoice.grossAmount).toLocaleString()
      } ${invoice.currency}`,
      document_no: invoice.qoyod_reference,
      status: invoice.status === 'paid' ? 'Paid' : 'Unpaid',
      paid_on:
        Number(invoice.grossAmount).toLocaleString() !== '0'
          ? formatDateString(invoice.chargedAt)
          : '-',
      action: invoice.invoiceUrl,
    }));
    setInvoices(invoices);
  }, [invoicesDetails]);

  useEffect(() => {
    if (isInitialState) {
      if (role === Role.ADMIN) {
        dispatch(
          fetchInvoicesAsync(
            0,
            PER_PAGE_LIMIT,
            setIsLoading,
            setErrorMessage,
            navigate,
            true,
            customerId,
          ),
        );
      } else {
        dispatch(
          fetchInvoicesAsync(0, PER_PAGE_LIMIT, setIsLoading, setErrorMessage, navigate, false),
        );
      }

      setIsInitialState(false);
    }
  }, []);

  useEffect(() => {
    if (signedUrl) {
      const downloadPdf = async () => {
        try {
          setErrorMessage('');
          const response = await fetch(signedUrl);
          if (!response.ok) setErrorMessage('Network response was not ok.');
          const blob = await response.blob();
          const blobUrl = window.URL.createObjectURL(blob);

          const qoyod_reference = invoices.filter(
            (invoice) => invoice.action === selectedActionItem,
          )[0].document_no;

          const link = document.createElement('a');
          link.href = blobUrl;

          const filePrefix = identificationNumber;

          const formattedDate = moment().format('L');

          link.download = `${filePrefix}-${formattedDate}-${qoyod_reference}.pdf`;

          link.click();

          setIsDownloadLoading(false);
          window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
          setIsDownloadLoading(false);
          setErrorMessage('There was an error while downloading the invoice.');
        }
      };

      downloadPdf();
    }
  }, [signedUrl]);

  const onPdfDownload = async (invoiceUrl: string | number) => {
    setSelectedActionItem(invoiceUrl);
    try {
      dispatch(
        fetchSignedInvoiceUrlAsync(
          invoiceUrl,
          setSignedUrl,
          setIsDownloadLoading,
          setErrorMessage,
          navigate,
          false,
        ),
      );
    } catch (error) {
      setErrorMessage('There was an error while downloading the invoice.');
    }
  };

  const onPreviousPageClick = () => {
    if (currentPage === 0) return;
    if (role === Role.ADMIN) {
      dispatch(
        fetchInvoicesAsync(
          currentPage - 1,
          PER_PAGE_LIMIT,
          setIsLoading,
          setErrorMessage,
          navigate,
          true,
          customerId,
        ),
      );
    } else {
      dispatch(
        fetchInvoicesAsync(
          currentPage - 1,
          PER_PAGE_LIMIT,
          setIsLoading,
          setErrorMessage,
          navigate,
          false,
        ),
      );
    }
    setCurrentPage(currentPage - 1);
  };

  const onNextPageClick = () => {
    if (currentPage === invoicesDetails.totalPages - 1) return;
    if (role === Role.ADMIN) {
      dispatch(
        fetchInvoicesAsync(
          currentPage + 1,
          PER_PAGE_LIMIT,
          setIsLoading,
          setErrorMessage,
          navigate,
          true,
          customerId,
        ),
      );
    } else {
      dispatch(
        fetchInvoicesAsync(
          currentPage + 1,
          PER_PAGE_LIMIT,
          setIsLoading,
          setErrorMessage,
          navigate,
          false,
        ),
      );
    }

    setCurrentPage(currentPage + 1);
  };

  const onPageNumberClick = (pageNumber: number) => {
    if (role === Role.ADMIN) {
      dispatch(
        fetchInvoicesAsync(
          pageNumber,
          PER_PAGE_LIMIT,
          setIsLoading,
          setErrorMessage,
          navigate,
          true,
          customerId,
        ),
      );
    } else {
      dispatch(
        fetchInvoicesAsync(
          pageNumber,
          PER_PAGE_LIMIT,
          setIsLoading,
          setErrorMessage,
          navigate,
          false,
        ),
      );
    }

    setCurrentPage(pageNumber);
  };

  const cellStyling = 'text-white-0 text-sm font-semibold border rounded-[5px] py-1';

  return (
    <div className="flex pb-[20px] px-[23px] ">
      <div>
        <NavigationBar tab={DashBoardTab.BILLING_HISTORY} />
      </div>
      <div className="flex flex-col ml-[33px] w-screen">
        <TitleCard title="Billing History" icon={BillingHistoryIcon} />
        {errorMessage ? (
          <p className="poppins-400 text-[14px] text-center text-red-0">{errorMessage}</p>
        ) : null}
        <DataTable
          tableHeader={BILLING_HISTORY_TABLE_HEADERS}
          data={invoices}
          height="h-[660px]"
          totalPages={invoicesDetails?.totalPages}
          currentPage={invoicesDetails?.currentPage}
          highlightTableColumnValue="status"
          highlightCondition="Paid"
          highlightedCellStyling={`bg-green-0 ${cellStyling}`}
          alternateHighlightedCellStyling={`bg-red-0 ${cellStyling}`}
          onButtonClick={onPdfDownload}
          onLeftArrowClick={onPreviousPageClick}
          onRightArrowClick={onNextPageClick}
          onPageNumberClick={onPageNumberClick}
          selectedActionItem={selectedActionItem}
          isDataLoading={isLoading}
          isLoading={isDownloadLoading}
          actionName="Download Invoice"
          errorMessage={totalItems === 0 && !isLoading ? 'No data available' : ''}
        >
          <img src={DownloadPdfIcon} alt="Download Pdf Icon" />
        </DataTable>
      </div>
    </div>
  );
};
export default BillingHistory;
