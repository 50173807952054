import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CopyIcon from '../../Assets/Images/svg/CopyIcon.svg';
import GreenTickIcon from '../../Assets/Images/svg/GreenTickIcon.svg';
import LogoSection from '../../Components/Common/LogoSection';
import Spinner from '../../Components/Common/Spinner';
import { AppDispatch, RootState } from '../../Store';
import { updateOnboardingSuccessAsync } from '../../Utils/api';
import { CREATE_PROJECT_URL } from '../../Utils/constants';
import ButtonsSection from './Common/ButtonsSection';

const Success = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [copied, setCopied] = useState(false);

  const { accountName, gcpSubBillingAccountId, pscPermissionGranted } = useSelector(
    (state: RootState) => state.signup.customer?.customer,
  );

  const handleCreateAccount = () => {
    dispatch(updateOnboardingSuccessAsync(setIsLoading, setErrorMessage, navigate));
  };

  const handleCopy = () => {
    const trimmedString = gcpSubBillingAccountId?.replace('billingAccounts/', '');
    if (trimmedString) {
      navigator.clipboard.writeText(trimmedString);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="border-[1px] border-gray-3 rounded-lg py-4 px-8 bg-white-0 h-[680px] w-[98%] overflow-auto flex flex-col">
          <LogoSection />
          <div className="w-full h-[1px] bg-gray-2"></div>
          <div className="flex justify-center items-center my-5 h-[98%] w-full">
            <div className="flex flex-col gap-3 justify-center items-center border border-gray-3 rounded-md py-5 px-8 md:px-20">
              <img className="w-20 h-auto" src={GreenTickIcon} alt="tick icon" />
              <h1 className="text-2xl poppins-700 text-left text-gray-1 whitespace-nowrap">
                Congratulations!
              </h1>
              <h3 className="text-lg text-left text-gray-1 poppins-400">
                Your Google Cloud Account has been created
              </h3>
              {!pscPermissionGranted && (
                <p className="text-sm text-gray-5 w-[500px]">
                  As a final step, we will be reaching out to you in the next few days to activate
                  your account. Once activated, you&apos;ll be able to start using Google Cloud.
                </p>
              )}
              <div className="flex flex-col gap-1 w-full">
                <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1 poppins-500">
                  Billing Account Name
                </h3>
                <input
                  className="flex w-full h-[44px] p-3 py-7 items-center rounded-md bg-gray-4 text-gray-1 poppins-400 text-sm"
                  type="text"
                  placeholder={accountName ?? ''}
                  disabled
                />
                <h3 className="text-left justify-between text-sm font-medium leading-loose text-gray-1 poppins-500">
                  Billing Account ID
                </h3>
                <div className="relative">
                  <input
                    className="flex w-full h-[44px] p-3 py-7 items-center rounded-md bg-gray-4 text-gray-1 poppins-400 text-sm"
                    type="text"
                    placeholder={
                      gcpSubBillingAccountId
                        ? gcpSubBillingAccountId.replace('billingAccounts/', '')
                        : 'NA'
                    }
                    disabled
                  />
                  <button onClick={handleCopy} className="absolute right-3 top-1/2">
                    <img
                      className="w-4 h-auto  transform -translate-y-1/2 text-gray-5 cursor-pointer"
                      src={CopyIcon}
                      alt="copy icon"
                    />
                  </button>
                  {copied && (
                    <p className="text-[12px] text-green-0 absolute right-[-3rem] top-4">Copied</p>
                  )}
                </div>
              </div>
              {errorMessage ? (
                <p className="text-[14px] text-red-0 max-w-[300px]">{errorMessage}</p>
              ) : null}
              <div className="flex gap-1 md:gap-4 w-full flex-col md:flex-row justify-center items-center">
                <button
                  className="w-[70%] mt-2 cursor-pointer bg-opacity-0 text-blue-1 hover:text-opacity-80 font-semibold text-xs text-nowrap"
                  onClick={handleCreateAccount}
                >
                  My CNTXT dashboard
                </button>
                <a
                  className="w-[70%] mt-2 cursor-pointer bg-opacity-0 text-blue-1 hover:text-opacity-80 font-semibold text-xs text-nowrap"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={CREATE_PROJECT_URL}
                >
                  Create your first project
                </a>
              </div>
            </div>
          </div>
          <div className="mt-auto">
            <ButtonsSection isBackButton={false} isNextButton={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Success;
